import './Carousel.css';
import React from "react";
import newsArticles from '../assets/newsArticles.js'
import mediaHeader from '../assets/mediaHeader.svg'



function Carousel(props) {
    let cardClass = props.isMobile ? "scrollableContainerCountyMobile" : "scrollableContainerCounty" 
    return (
        <div>
            <img className="mediaHeader" src={mediaHeader} alt="As seen in"></img>
            <div class={cardClass}>
                {newsArticles.map((item, index) => (
                    <div className="cardCounty"  onClick={() => window.open(item.link, '_blank')}>
                        <p>"{item.quote}"</p>
                        <p>-{item.source}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Carousel;