import './photoPages.css';
import React from "react";


function EVdrinksmenu() {
    return (
        <div class="body">
<img src="https://live.staticflickr.com/65535/53800477916_c6b245fcd2_k.jpg" width="100%" height="auto" alt="full food menu ev including combos like"/>
<script async src="//embedr.flickr.com/assets/client-code.js" charset="utf-8"></script>
        </div>
        )
}

export default EVdrinksmenu;