import './Footer.css';
import React from "react";
import instagram from '../assets/instagram.png'
import tiktok from '../assets/tiktok.png'
import calendar from '../assets/calendar.png'
// import youtube from '../assets/youtube.png'
// import mail from '../assets/mail.png'

function Footer() {

    const links = [
    //     {
    //     route: "mailto:inoodlefactory@gmail.com",
    //     icon: mail,
    //     label: "Mail"
    // }, 
    {
        route: "https://www.instagram.com/instantnoodlefactory/",
        icon: instagram,
        label: "Instagram"
    },  {
        route: "https://www.tiktok.com/@instantnoodlefactory",
        icon: tiktok,
        label: "TikTok"
    }, {
        route: "/eventscalendar",
        icon: calendar,
        label: "Calendar"
    }]

    return (
        <div class="footer">
            <p class="questions"> questions? text us at (914) 510-2407 </p>
            <p class="addressFooter"> 24-11 41st Avenue, Long Island City NY</p>
            <p class="addressFooter"> 130 East 7th Street, New York NY</p>
            <div class="IconRow">
                {links.map((link) => (
                    <a href={link.route} target="_blank" rel="noreferrer">
                        <img class="Icon" src={link.icon} alt={link.label}></img>
                    </a>
                ))}
            </div>
        </div>
    )
}

export default Footer;