import React from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

function Slideshow() {

  const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '100px',
  }

  const slideImages = [
    {
      src: "https://live.staticflickr.com/65535/53800526335_1fefe45d46_h.jpg",
      caption: 'classic tonkotsu combo bowl',
      title: "classic tonkotsu",
      alt: "classic tonkotsu"
    },
    {
      src: "https://live.staticflickr.com/65535/53800529395_7360404073_h.jpg",
      caption: 'buldak with birria',
      title: "buldak birria",
      alt: "buldak birria"
    },
    {
      src: "https://live.staticflickr.com/65535/53800102161_240181e445_h.jpg",
      caption: 'various combos',
      title: "combos flatlay",
      alt: "flatlay of various combos"
    }, {
      src: "https://live.staticflickr.com/65535/53800427514_a08ad4ac8f_h.jpg",
      caption: 'spicy section of noodle wall',
      title: "spicy section of noodle wall",
      alt: "spicy section of noodle wall"
    }
  ]
  return (
    <Slide
      nextArrow={
        <button style={{ background: "none", border: "0px", width: "30px", }}>
          <svg fill="#fff" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
            <path d="M512 256L270 42.6v138.2H0v150.6h270v138z" />
          </svg>
        </button>
      }
      prevArrow={
        <button style={{ background: "none", border: "0px", width: "30px", }}>
          <svg fill="#fff" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
            <path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z" />
          </svg>
        </button>
      }
    >
      {slideImages.map((slideImage, index) => (
        <div key={index} style={{ divStyle }}>
          <img src={slideImage.src} width="95%" height="auto" alt='slideImage.alt'/><script async src="//embedr.flickr.com/assets/client-code.js" charset="utf-8"></script>
        </div>
      ))}
    </Slide>
  );
}
export default Slideshow;