import './Custom.css';
import React from "react";

function Custom() {


    return (
        <div class="order">
            <p> Build a Custom Bowl</p>
        </div>
    )
}

export default Custom;