//import './LIC.css';
import React, { Component } from "react";
import Locations from '../../assets/Locations.js'

class LIC extends Component {
    constructor () {
        super()
        this.locationAddress = "24-11 41st Avenue"
        this.location = 0
        this.locationMap = "https://www.google.com/maps/embed/v1/place?q=place_id:ChIJbdkMophZwokRnJNjVVLsBgw&key=AIzaSyD6hUs_dQsCTTXl-djRuEPj5HTUevL1pA0"
        this.blerb = "our first location features self service noodle cooking machines and an ordering kiosk"
        this.hours = "open daily 11:30am-9pm"
    }
    
    render() {
        return (
            <div>
                <Locations 
                    locationAddress = {this.locationAddress} 
                    location = {this.location}
                    locationMap = {this.locationMap}
                    blerb = {this.blerb}
                    hours = {this.hours} >
                </Locations>
            </div>
        )
    }
}
export default LIC;