import './Shop.css';
import React, { Component } from "react";
import Header from './Header.js'

class Shop extends Component {

    componentDidMount() {
        var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
        if (window.ShopifyBuy) {
            if (window.ShopifyBuy.UI) {
                ShopifyBuyInit();
            } else {
                loadScript();
            }
        } else {
            loadScript();
        }
        function loadScript() {
            var script = document.createElement('script');
            script.async = true;
            script.src = scriptURL;
            (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
            script.onload = ShopifyBuyInit;
        }
        function ShopifyBuyInit() {
            var client = window.ShopifyBuy.buildClient({
                domain: 'd0645e-2.myshopify.com',
                storefrontAccessToken: '497265ce249ce524a71c18e8e6f513b4',
            });
            window.ShopifyBuy.UI.onReady(client).then(function (ui) {
                ui.createComponent('collection', {
                    id: '459170382123',
                    node: document.getElementById('collection-component-1695751191165'),
                    moneyFormat: '%24%7B%7Bamount%7D%7D',
                    options: {
                        "product": {
                            "styles": {
                                "product": {
                                    "@media (min-width: 601px)": {
                                        "max-width": "calc(50% - 40px)",
                                        "margin-left": "40px",
                                        "margin-bottom": "50px",
                                        "width": "calc(50% - 40px)"
                                    }
                                },
                                "title": {
                                    "font-size": "20px"
                                },
                                "button": {
                                    "font-weight": "bold",
                                    "font-size": "16px",
                                    "padding-top": "16px",
                                    "padding-bottom": "16px",
                                    "color": "#fffdfd",
                                    ":hover": {
                                        "color": "#fffdfd",
                                        "background-color": "#bc5f4e"
                                    },
                                    "background-color": "#d16957",
                                    ":focus": {
                                        "background-color": "#bc5f4e"
                                    },
                                    "border-radius": "27px"
                                },
                                "quantityInput": {
                                    "font-size": "16px",
                                    "padding-top": "16px",
                                    "padding-bottom": "16px"
                                },
                                "price": {
                                    "font-weight": "bold"
                                },
                                "compareAt": {
                                    "font-weight": "bold"
                                },
                                "unitPrice": {
                                    "font-weight": "bold"
                                }
                            },
                            "buttonDestination": "modal",
                            "contents": {
                                "options": false
                            },
                            "text": {
                                "button": "Details"
                            }
                        },
                        "productSet": {
                            "styles": {
                                "products": {
                                    "@media (min-width: 601px)": {
                                        "margin-left": "-40px"
                                    }
                                }
                            }
                        },
                        "modalProduct": {
                            "contents": {
                                "img": false,
                                "imgWithCarousel": true,
                                "button": false,
                                "buttonWithQuantity": true
                            },
                            "styles": {
                                "product": {
                                    "@media (min-width: 601px)": {
                                        "max-width": "100%",
                                        "margin-left": "0px",
                                        "margin-bottom": "0px"
                                    }
                                },
                                "button": {
                                    "font-weight": "bold",
                                    "font-size": "16px",
                                    "padding-top": "16px",
                                    "padding-bottom": "16px",
                                    "color": "#fffdfd",
                                    ":hover": {
                                        "color": "#fffdfd",
                                        "background-color": "#bc5f4e"
                                    },
                                    "background-color": "#d16957",
                                    ":focus": {
                                        "background-color": "#bc5f4e"
                                    },
                                    "border-radius": "27px"
                                },
                                "quantityInput": {
                                    "font-size": "16px",
                                    "padding-top": "16px",
                                    "padding-bottom": "16px"
                                },
                                "title": {
                                    "font-family": "Helvetica Neue, sans-serif",
                                    "font-weight": "bold",
                                    "font-size": "26px",
                                    "color": "#4c4c4c"
                                },
                                "price": {
                                    "font-family": "Helvetica Neue, sans-serif",
                                    "font-weight": "bold",
                                    "font-size": "18px",
                                    "color": "#4c4c4c"
                                },
                                "compareAt": {
                                    "font-family": "Helvetica Neue, sans-serif",
                                    "font-weight": "bold",
                                    "font-size": "15.299999999999999px",
                                    "color": "#4c4c4c"
                                },
                                "unitPrice": {
                                    "font-family": "Helvetica Neue, sans-serif",
                                    "font-weight": "bold",
                                    "font-size": "15.299999999999999px",
                                    "color": "#4c4c4c"
                                },
                                "description": {
                                    "font-weight": "bold"
                                }
                            },
                            "text": {
                                "button": "Add to Noodle Cart"
                            }
                        },
                        "option": {},
                        "cart": {
                            "styles": {
                                "button": {
                                    "font-weight": "bold",
                                    "font-size": "16px",
                                    "padding-top": "16px",
                                    "padding-bottom": "16px",
                                    "color": "#fffdfd",
                                    ":hover": {
                                        "color": "#fffdfd",
                                        "background-color": "#bc5f4e"
                                    },
                                    "background-color": "#d16957",
                                    ":focus": {
                                        "background-color": "#bc5f4e"
                                    },
                                    "border-radius": "27px"
                                },
                                "title": {
                                    "color": "#000000"
                                },
                                "header": {
                                    "color": "#000000"
                                },
                                "lineItems": {
                                    "color": "#000000"
                                },
                                "subtotalText": {
                                    "color": "#000000"
                                },
                                "subtotal": {
                                    "color": "#000000"
                                },
                                "notice": {
                                    "color": "#000000"
                                },
                                "currency": {
                                    "color": "#000000"
                                },
                                "close": {
                                    "color": "#000000",
                                    ":hover": {
                                        "color": "#000000"
                                    }
                                },
                                "empty": {
                                    "color": "#000000"
                                },
                                "noteDescription": {
                                    "color": "#000000"
                                },
                                "discountText": {
                                    "color": "#000000"
                                },
                                "discountIcon": {
                                    "fill": "#000000"
                                },
                                "discountAmount": {
                                    "color": "#000000"
                                }
                            },
                            "text": {
                                "title": "Noodle Cart",
                                "total": "Subtotal",
                                "empty": "Your cart has no noodles :(",
                                "button": "Checkout"
                            }
                        },
                        "toggle": {
                            "styles": {
                                "toggle": {
                                    "font-weight": "bold",
                                    "background-color": "#d16957",
                                    ":hover": {
                                        "background-color": "#bc5f4e"
                                    },
                                    ":focus": {
                                        "background-color": "#bc5f4e"
                                    }
                                },
                                "count": {
                                    "font-size": "16px",
                                    "color": "#fffdfd",
                                    ":hover": {
                                        "color": "#fffdfd"
                                    }
                                },
                                "iconPath": {
                                    "fill": "#fffdfd"
                                }
                            }
                        },
                        "lineItem": {
                            "styles": {
                                "variantTitle": {
                                    "color": "#000000"
                                },
                                "title": {
                                    "color": "#000000"
                                },
                                "price": {
                                    "color": "#000000"
                                },
                                "fullPrice": {
                                    "color": "#000000"
                                },
                                "discount": {
                                    "color": "#000000"
                                },
                                "discountIcon": {
                                    "fill": "#000000"
                                },
                                "quantity": {
                                    "color": "#000000"
                                },
                                "quantityIncrement": {
                                    "color": "#000000",
                                    "border-color": "#000000"
                                },
                                "quantityDecrement": {
                                    "color": "#000000",
                                    "border-color": "#000000"
                                },
                                "quantityInput": {
                                    "color": "#000000",
                                    "border-color": "#000000"
                                }
                            }
                        }
                    }
                })
            })
        }
    }
    render() {
        return (
            <div>
                <Header text="FREE SHIPPING on orders $35.00+ !!"></Header>
                <div class="shop">
                    <div id='collection-component-1695751191165'></div>
                </div>
            </div>
        )
    }
}
export default Shop;