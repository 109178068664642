import './FormSuccess.css';
import React from "react";
import twentyoff from '../../assets/twentyoff.png'


function FormSuccess() {
    const d = new Date();
    return (
        <div>
                <a href={"/"} rel="noreferrer" class="order-href">
                    <p class="backText"> back to home page </p>
                </a>
            <p class="discountText"> Thanks for Subscribing! </p>
            <img className="twentyOff" src={twentyoff} alt="take twenty percent off your first order"></img>
            <p className="classCode"> your code: emailtwenty{d.getMonth()}{d.getDate()}{d.getHours()}{d.getMinutes()}</p>
            <p className="classCode"> save this code! we recommend screenshotting this page</p>
            <p class="formSuccessText"> to use at LIC: must enter order via online site - kiosk does not accept promo codes! follow QR code in store to build your bowls, and then at checkout, enter your promo code. Your promo code is unique and can only be used once!</p>
            <p class="formSuccessText"> to use at EV: show this page at checkout! not valid for bottomless brunch</p>
        </div>
    )
}

export default FormSuccess;