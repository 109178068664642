import './photoPages.css';
import React from "react";


function SipAndSlurp() {
    return (
        <div class="body">
            <img src="https://live.staticflickr.com/65535/53923165705_704bfac3d3_b.jpg" width="100%" height="auto" alt="full food menu ev including combos like"/>
            <script async src="//embedr.flickr.com/assets/client-code.js" charset="utf-8"></script>
        </div>
    )
}

export default SipAndSlurp;