import './Locations.css';
import welcome from "../assets/welcome.svg"
import LICaddress from "../assets/LICaddress.svg"
import EVaddress from "../assets/EVaddress.svg"
import EmailForm from "../pages/email/EmailForm.js"
import React from "react";

function Locations(props) {
  const icons = [LICaddress, EVaddress];
  let orderButton;
  if (props.location === 0) {
    orderButton = <div>
      <div className="OrderButton">
        <a href={"https://www.toasttab.com/instantnoodlefactory/v3"} target="_blank" rel="noreferrer" class="order-href">
          <p class="order"> Order Pick Up </p>
        </a>
      </div>
      <div className="OrderButton">
        <a href={"https://www.order.store/store/instant-noodle-factory/eWiDE0J9UD-dRkzAxAQnQA/1be2c05b-a38f-44fa-9472-6b4d1021bbd6?diningMode=DELIVERY"} target="_blank" rel="noreferrer" class="order-href">
          <p class="order"> Order on UberEats </p>
        </a>
        <a href={"https://www.grubhub.com/restaurant/instant-noodle-factory-24-11-41st-ave-queens/6481280"} target="_blank" rel="noreferrer" class="order-href">
          <p class="order"> Order on Grubhub </p>
        </a>
      </div>
    </div>
  } else {
    orderButton = <div>
      <div className="OrderButton">
      <p className="orderHeaders"> bottomless options</p>
      <a href={"/brunch"} target="_blank" rel="noreferrer" class="order-href">
          <p class="order"> Bottomless Brunch! </p>
        </a>
        <a href={"/sipandslurp"} target="_blank" rel="noreferrer" class="order-href">
          <p class="order"> Endless Sip & Slurp! </p>
        </a>
        <p className="orderHeaders"> standard menu</p>
      <a href={"/evfood"} target="_blank" rel="noreferrer" class="order-href">
          <p class="order"> View Food Menu </p>
        </a>
        <a href={"/evdrinks"} target="_blank" rel="noreferrer" class="order-href">
          <p class="order"> View Drinks Menu </p>
        </a>
        <p className="orderHeaders"> order delivery</p>
        <a href={"https://www.ubereats.com/store/instant-noodle-factory-les/Hi2eTxa3Xd6YK-hyEih5Rw?diningMode=DELIVERY"} target="_blank" rel="noreferrer" class="order-href">
          <p class="order"> Order on UberEats </p>
        </a>
        <a href={"https://www.grubhub.com/restaurant/instant-noodle-factory---downtown-130-e-7th-st-new-york/7805200"} target="_blank" rel="noreferrer" class="order-href">
          <p class="order"> Order on Grubhub </p>
        </a>
      </div>
    </div>
  }

  return (
    <div className="locations">
      <div className="images">
        <a href={'/'} rel="noreferrer">
        <img className="welcome" src={welcome} alt="Welcome to Instant Noodle Factory"></img>
        <img className="welcome" src={icons[props.location]} alt={props.locationAddress}></img>
        </a>
      </div>
      <p className="blerb"> {props.blerb} </p>
      <p className="hours"> {props.hours} </p>
      {orderButton}
      <EmailForm></EmailForm>
      <iframe title="24-11" width="93%" height="20%" loading="lazy" src={props.locationMap}></iframe>
    </div>
  )
}

export default Locations;