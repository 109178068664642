import './Preset.css';
import React from "react";

function Preset() {


    return (
        <div class="order">
            <p> Choose a preset bowl</p>
        </div>
    )
}

export default Preset;