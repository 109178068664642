import './subpages.css';
import React from "react";
import ContactForm from "./ContactForm.js";
import Footer from "../Footer.js"


function Events() {
    return (
        <div>
            <p class="header"> Events</p>
            <p class="body"> Instant Noodle Factory might be the perfect venue for your next event! We can cater to large groups with our unique noodle dining experience with customizable options. Contact us below to discuss how INF can make your gathering extraordinary!</p>
            <ContactForm />
            <Footer/>
        </div>
                )
}

export default Events;