import './subpages.css';
import React from "react";

function Catering() {
    return (
        <div class="main">
            <p> Catetering</p>
            </div>
                )
}

export default Catering;