import './Header.css';
import React from "react";
import { useNavigate } from "react-router-dom";

function Header(props) {
    const navigate = useNavigate();

  function handleClick() {
    navigate("/shop");
  }

    return (
        <div class="scroll-parent">
            <div class="scroll-element primary" onClick={handleClick}>
                {props.text}
            </div>
            <div class="scroll-element secondary" onClick={handleClick}>
                {props.text}
            </div>
        </div>
    )
}

export default Header;