import './subpages.css';
import React from "react";
import bbicon from "../../assets/bbicon.png"
import bblogo from "../../assets/bblogo.png"


function Brunch() {
    return (
        <div class="body">
                <img className="bbicon" src={bbicon} alt="bottomless brunch text surrounds a noodle bowl with fried eggs and corn"></img>
                <img className="bblogo" src={bblogo} alt="bottomless brunch text surrounds a noodle bowl with fried eggs and corn"></img>
                <a className="reservationlink" href="https://www.opentable.com/r/instant-noodle-factory-downtown-reservations-new-york?restref=1376461&lang=en-US&ot_source=Restaurant%20website" target="_blank" rel="noreferrer">
                    <p class="reservation"> Reserve a Table via OpenTable!</p>
                </a>
                <div>
                    <h1 class="menuHeader"> MENU </h1>
                    <div className="menuOption">
                        <h1 class="menu"> Option A: $35</h1>
                        <p class="menuDescription"> Bottomless Noodles + Bottomless Brunch Cocktails -all you can eat noodles, classic toppings platter, and bottomless brunch cocktails featuring our lycheetini, tokyo mule, and soju sunrise -optional $5 unlimited fried egg add on</p>
                    </div>
                    <div className="menuOption">
                        <h1 class="menu"> Option B: $15 </h1>
                        <p class="menuDescription"> Bottomless Noodles -all you can eat noodles with a classic toppings platter -optional $5 unlimited fried egg add on</p>
                    </div>
                    <div className="menuOption">
                        <h1 class="menu"> Option C: $35</h1>
                        <p class="menuDescription"> Bottomless Brunch Cocktails with Entree -bottomless brunch cocktails featuring our lycheetini, tokyo mule, and soju sunrise -choice of 6 crafted brunch noodle/rice bowls</p>
                    </div>
                </div>
            </div>
        )
}

export default Brunch;