import './Order.css';
import React from "react";
import { NavLink } from "react-router-dom";

function Order() {


    return (
        <div class="order">
            <p> Order on your phone for the best ordering experience!</p>
            <NavLink activeClassName="active" className="link" type="submit" to="/Preset">
                <p> Presets </p>
            </NavLink>
            <NavLink activeClassName="active" className="link" type="submit" to="/Custom">
                <p> Build a Bowl </p>
            </NavLink>
        </div>
    )
}

export default Order;