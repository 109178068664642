
const newsArticles = [
    { "quote": "This new mostly automated spot is both bewildering and delightful.", "source": "Robert Sietsema, Eater NY", "link": "https://ny.eater.com/2023/7/21/23801556/instant-ramen-factory-long-island-city-opening"},
    { "quote": "The idea is to elevate the humble noodle into a gourmet masterpiece.", "source": "Justine Golata, Secret NYC", "link": "https://secretnyc.co/instant-noodle-factory-nyc/"},
    { "quote": "There are so many things here that you can't really find anywhere else", "source": "Lauren Scala, NBC New York Live", "link": "https://www.nbcnewyork.com/entertainment/the-scene/new-york-live/an-instant-noodle-lovers-dream-just-opened-in-long-island-city/4593972/"},
    { "quote": "Classic tonkotsu, beef birria and kimchi cheese noodles are all on the menu.", "source": "Melissa Kravitz Hoeffner, TimeOut", "link": "https://www.timeout.com/newyork/news/taste-88-types-of-instant-noodles-at-long-island-citys-new-ramen-restaurant-072423"},
    { "quote": "Go check it out! It's truly a heaven for Instant Noodle lovers", "source": "@feed your girlfriend", "link": "https://www.instagram.com/reel/Cuh93EVOvRT/?igshid=MzRlODBiNWFlZA=="},
    { "quote": "Instant Noodle Factory is a proverbial promised land for lovers of ramen.", "source": "Ayomari, FoodBeast", "link": "https://www.foodbeast.com/news/instant-noodle-factory-nyc/"}
]

export default newsArticles;