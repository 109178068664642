import './LICLinks.css';
import React, { Component } from "react";
import Links from '../../assets/Links.js'

class LICLinks extends Component {
    constructor () {
        super()
        this.LIClinks = [
            {"text": "Order Dessert", "link": "https://order.toasttab.com/online/instantnoodlefactory"}, 
            {"text": "Leave a Review", "link": "https://g.page/r/CZyTY1VS7AYMEB0/review"},
            {"text": "Follow our Instagram", "link": "https://www.instagram.com/instantnoodlefactory/"},
            {"text": "Follow our TikTok", "link": "https://www.tiktok.com/@instantnoodlefactory"},
            {"text": "Give us Feedback", "link": "https://forms.gle/Af5Sau64jBk1UGmD6"},
            {"text": "Visit our Homepage", "link": "https://inoodlefactory.com/"},
        ]
    }
    
    render() {
        return (
            <div>
                <Links links = {this.LIClinks} > </Links>
            </div>
        )
    }
}
export default LICLinks;