import './photoPages.css';
import React from "react";


function EVfoodmenu() {
    return (
        <div class="body">
            <img src="https://live.staticflickr.com/65535/53800719298_2b04de46a0_k.jpg" width="100%" height="auto" alt="ev drinks menu featuring "/>
            <script async src="//embedr.flickr.com/assets/client-code.js" charset="utf-8"></script>
        </div>
        )
}

export default EVfoodmenu;