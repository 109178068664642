import './EmailForm.css';
import React from "react";



function EmailForm() {

    return (
        <div>
          <p class="discountText"> get 20% off </p>
          <p class="emailText"> your next order + receive exclusive weekly coupons by signing up to our noodle notification list!</p>
          <form action="https://getform.io/f/95adf52b-5193-43d5-868d-88e2522e3d44" method="POST">
            <input className="typeBox" type="text" placeholder="Enter email address" name="email" required />
            <input className="submitBox" type="submit" name="submit" value="Submit" />
          </form>
        </div>
    )
}

export default EmailForm;