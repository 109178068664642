import React, { Component } from "react";
import Links from '../../assets/Links.js'

class EVLinks extends Component {
    constructor () {
        super()
        this.EVLinks = [
            {"text": "Job Application!", "link": "https://docs.google.com/forms/d/e/1FAIpQLSeWySo0Zzs0IAQYo170yGV1JucNW5VceU3HxcP6RFQCIOisfw/viewform?fbclid=IwAR0ijv7wfTp1LGoGnZgjcNBkncW9MZqTj1QVX47IO0mqYF2yp3ev0j43c5M"},
            {"text": "Follow our Instagram", "link": "https://www.instagram.com/instantnoodlefactory/"},
            {"text": "Follow our TikTok", "link": "https://www.tiktok.com/@instantnoodlefactory"},
            {"text": "Visit our Homepage", "link": "https://inoodlefactory.com/"},
        ]
    }
    
    render() {
        return (
            <div>
                <Links links = {this.EVLinks} > </Links>
            </div>
        )
    }
}
export default EVLinks;